import { ITab } from '../../_models/tabsInterface'

export const headerTabs: ITab[] = [
  {
    id: 1,
    label: 'Features',
    path: '/coming-soon',
  },
  {
    id: 2,
    label: 'Blog',
    path: '/coming-soon',
  },
  {
    id: 3,
    label: 'Contact',
    path: '/contact-us',
  },
]
